import { Autocomplete, Chip, TextField } from "@mui/material";
import { useContext, useMemo } from "react";
import { COMPANY_STATUS } from "../../../commons/enum";
import SearchInputComponent from "../../../components/contact-search-input";
import CompanyContext from "../../../context/company-context";
import "./company-search.scss";

const companyStatus = [
  { title: "Approved", value: COMPANY_STATUS.APPROVED },
  { title: "Awaiting Approval", value: COMPANY_STATUS.AWAITING_APPROVAL },
  { title: "Rejected", value: COMPANY_STATUS.REJECTED },
];

const CompanySearch = () => {
  const {
    handleCompanySearch,
    selectedCompanyStatus,
    setSelectedCompanyStatus,
  } = useContext(CompanyContext);
  const filteredStaffRoleList = useMemo(() => {
    return companyStatus.filter((item) => {
      return !selectedCompanyStatus.some((role) => role.value === item.value);
    });
  }, [selectedCompanyStatus]);

  const generateChipColor = (title) => {
    switch (title) {
      case "Approved":
        return {
          backgroundColor: "#cbfbf1",
          color: "#0b9185",
          borderColor: "#0b9185",
        };
      case "Awaiting Approval":
        return {
          backgroundColor: "#fef2c7",

          color: "#d97708",
          borderColor: "#d97708",
        };
      case "Rejected":
        return {
          backgroundColor: "#ffe4e6",
          color: "#e11d48",
          borderColor: "#e11d48",
        };
      default:
        return "";
    }
  };
  return (
    <div className="company-filter__container">
      <div className="company-filter__input">
        <SearchInputComponent handleSearch={handleCompanySearch} />
      </div>
      <div className="company-filter__filter">
        <Autocomplete
          multiple
          id="tags-company-status"
          options={filteredStaffRoleList}
          value={selectedCompanyStatus || []}
          getOptionLabel={(option) => option.title || ""}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          onChange={(event, value) => {
            setSelectedCompanyStatus(value);
          }}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Company Status" />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                style={generateChipColor(option.title)}
                key={option.title}
                variant="outlined"
                label={`${option.title}`}
              />
            ))
          }
        />
      </div>
    </div>
  );
};

CompanySearch.propTypes = {};

export default CompanySearch;
