import { useMemo, useState } from "react";
import { COMPANY_STATUS } from "../../commons/enum";
import { CustomOutlineButton } from "../../components/custom-button";
import HeaderComponent from "../../components/HeaderComponent";
import CompanyContext from "../../context/company-context";
import CompanyAdminModal from "../../features/company/company-admin-modal";
import ApprovalDialog from "../../features/company/company-approval-dialog";
import CompanySearch from "../../features/company/company-search";
import CompanyTable from "../../features/company/company-table";
import { useCompanyList } from "../../hooks/company.hooks";
import "./company.scss";

const columns = [
  { id: "companyName", label: "Company name", minWidth: 170 },
  { id: "companyDetails", label: "Company details", minWidth: 100 },
  {
    id: "nameOfBussiness",
    label: "Name of business ",
    minWidth: 100,
  },
  {
    id: "numberOfUsers",
    label: "Number of users",
    minWidth: 100,
  },
  {
    id: "totalWealth",
    label: "Total Wealth Under Management",
    minWidth: 100,
  },
  {
    id: "actionButton",
    label: "Action",
    minWidth: 100,
  },
];

const createData = ({
  companyName,
  companyDetails,
  nameOfBussiness = "",
  numberOfUsers = 0,
  totalWealth = 0,
  companyStatus = COMPANY_STATUS.WAITING_APPROVED,
  handleViewAdmin,
  handleCompanyApproval,
}) => {
  return {
    companyName,
    companyDetails,
    nameOfBussiness,
    numberOfUsers,
    totalWealth,
    actionButton: (
      <div className="company-table__action-button-container">
        {companyStatus === COMPANY_STATUS.APPROVED && (
          <CustomOutlineButton label="View Admin" onClick={handleViewAdmin} />
        )}
        {companyStatus === COMPANY_STATUS.AWAITING_APPROVAL && (
          <>
            <CustomOutlineButton
              label="Approve"
              onClick={() => handleCompanyApproval(true)}
            />
            <CustomOutlineButton
              label="Reject"
              onClick={() => handleCompanyApproval(false)}
            />
          </>
        )}
      </div>
    ),
  };
};

const Company = () => {
  const { data: companyList = [] } = useCompanyList();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenCompanyAdminDialog, setIsOpenCompanyAdminDialog] = useState(
    false
  );
  const [isOpenShareDetailDialog, setIsOpenShareDetailDialog] = useState(false);
  const [contactSearchQuery, setContactSearchQuery] = useState("");
  const [selectedCompanyStatus, setSelectedCompanyStatus] = useState([
    { title: "Approved", value: COMPANY_STATUS.APPROVED },
  ]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [isApprove, setIsApprove] = useState(false);

  const handleCompanySearch = (value) => {
    setContactSearchQuery(value);
  };
  const handleDialog = () => {
    setIsOpenDialog(!isOpenDialog);
  };

  const handleViewCompanyAdminDialog = (company) => {
    setSelectedCompany(company);
    setIsOpenCompanyAdminDialog(!isOpenCompanyAdminDialog);
  };

  const handleApprovalCompany = (company, isApproveCompany = false) => {
    setSelectedCompany(company);
    setIsApprove(isApproveCompany);
    setIsOpenShareDetailDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenShareDetailDialog(false);
  };

  const buildContactListData = useMemo(() => {
    return companyList
      .filter((company) => {
        return company.name
          .toLowerCase()
          .includes(contactSearchQuery.toLowerCase());
      })
      .filter((company) => {
        if (selectedCompanyStatus.length === 0) {
          return true;
        }
        return selectedCompanyStatus.some(
          (status) => status.value === company.status
        );
      })
      .map((company) => {
        const { name, adminDetails, status } = company;
        return createData({
          companyName: name,
          companyDetails: {
            email: adminDetails?.user?.email,
            phoneNumber: adminDetails?.user?.phoneNumber,
          },
          companyStatus: status,
          handleCompanyApproval: (isApproveCompany = false) =>
            handleApprovalCompany(company, isApproveCompany),
          handleViewAdmin: () => handleViewCompanyAdminDialog(company),
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyList, contactSearchQuery, selectedCompanyStatus]);

  return (
    <CompanyContext.Provider
      value={{
        handleDialog,
        handleApprovalCompany,
        handleCompanySearch,
        selectedCompanyStatus,
        setSelectedCompanyStatus,
      }}
    >
      <HeaderComponent />
      <div className="company-page">
        <CompanySearch />
        <CompanyTable
          tableHeader={columns}
          contactList={buildContactListData}
        />
      </div>
      <ApprovalDialog
        isApprove={isApprove}
        company={selectedCompany}
        isOpen={isOpenShareDetailDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <CompanyAdminModal
        isOpen={isOpenCompanyAdminDialog}
        handleClose={handleViewCompanyAdminDialog}
        companyDetails={selectedCompany}
      />
    </CompanyContext.Provider>
  );
};

Company.propTypes = {};

export default Company;
